<template>
  <div id="brandManage">
    <a-form layout="inline">
      <a-form-item>
        <a-select v-model="searchParams.selectType" style="width: 120px" :form="form">
          <a-select-option value="title">品牌名称</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-input v-model.trim="searchParams.name" placeholder="请输入品牌名称" allowClear />
      </a-form-item>
      <a-form-item>
        <a-button @click="search" type="primary" html-type="submit" :loading="loading">查询</a-button>
      </a-form-item>
      <div class="ptb-10">
        <a-button type="primary" v-auth="'basic_brand_add'" @click="operateShop('add')">添加品牌</a-button>
      </div>
    </a-form>
    <a-modal :destroyOnClose="true" v-model="isOperateShop" :title="modalTitle">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="operateShopRequest">
        <a-form-item label="品牌名称">
          <a-input :maxLength=30 v-model="brandName" v-decorator="['brandName', { rules: [{ required: true, message: '请输入品牌名称' }], initialValue: brandName }]" />
        </a-form-item>
        <a-form-item label="品牌图片">
          <a-upload :showUploadList="false" :customRequest="handleFileUpload" accept="image/*" :file-list="fileList" v-decorator="['brandPic', { rules: [{ required: true, message: '请上传图片' }], initialValue: brandPic }]">
            <a-button>
              <a-icon type="upload" /> 上传图片
            </a-button>
            <span class="limitSize">建议尺寸：90*90</span>
          </a-upload>
          <div v-show="brandPic" style="margin-top: 10px"><img style="max-width: 150px;" :src="brandPic" alt=""></div>
        </a-form-item>
        <a-form-item label="品牌简介">
          <a-input type="textarea" :auto-size="{ minRows: 3, maxRows: 5 }" :maxLength="200" v-model="brandIntroduce" v-decorator="['brandIntroduce', { rules: [{ required: true, message: '请输入品牌简介' }], initialValue: brandIntroduce }]" />
        </a-form-item>
        <a-form-item class="form_btn">
          <a-button class="mr-30" @click="isOperateShop=false">取消</a-button>
          <a-button type="primary" html-type="submit">确定</a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-table :scroll="{ y: tableScrollY }" v-auth="'basic_brand_list'" :rowKey="record => record.id" :columns="Columns" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
      <template slot="deadlineTime" slot-scope="record">
        <span>{{record.apply_deadline_time ? record.apply_deadline_time.substr(0, 16) : ''}}</span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button type="primary" size="small" @click="operateShop('edit',record)" v-auth="'basic_brand_modify'">编辑</a-button>
      </template>
    </a-table>

  </div>
</template>

<script>
import axios from 'axios';
import { brandList, addBrand, editBrand } from "@/service/operate";
import { getOssToken } from "@/service/user";
import { generateUUID } from "@/utils/index";
import { checkPer } from "@/utils/index";

const Columns = [
  {
    title: "品牌名称",
    dataIndex: "name",
    align: "left"
  },
  {
    title: "操作",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "special",
  data() {
    return {
      tableScrollY: 600,
      loading: false,
      fileList: [],
      modalTitle: "新建店铺",
      presentStatus: "add", // 当前状态是新增还是修改
      form: this.$form.createForm(this, { name: "coordinated" }),
      isOperateShop: false,
      brandName: "",
      brandPic: "",
      brandIntroduce: "",
      recordRow: "", //  点击编辑那一行的数据
      oss: null,
      Columns,
      listLoading: true,
      list: [],
      searchParams: {
        selectType: "title",
        name: ""
      }, //  搜索字段
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 345;
  },
  mounted() {
    this.getTopicList();
  },
  methods: {
    // 获取osstoken
    async getOssToken() {
      const { err, res } = await getOssToken();
      if (!err && res.success) this.oss = res.data;
    },
    // 文件上传方法
    async handleFileUpload(file) {
      // 图片尺寸校验
      let img = new Image();
      let reader = new FileReader(); //创建FileReader接口（把文件放到图片预览框里面）
      reader.onloadend = () => {
        img.src = reader.result;
        img.onload = async () => {
          if (img.width == 90 && img.height == 90) {
            if (!this.oss) await this.getOssToken(file);
            if (!this.oss) {
              this.$message.warn("上传失败");
              return false;
            }
            const formData = new FormData();
            const pathArr = file.file.name.split(".");
            const key = `${this.oss.dir}douyin-goods/${generateUUID()}.${
              pathArr[pathArr.length - 1]
            }`;
            formData.append("key", key);
            formData.append("policy", this.oss.policy);
            formData.append("signature", this.oss.signature);
            formData.append("success_action_status", "200");
            formData.append("OSSAccessKeyId", this.oss.accessid);
            formData.append("file", file.file);

            axios({
              method: "post",
              url: this.oss.host,
              data: formData
            })
              .then(response => {
                const { err } = response;
                if (!err) {
                  const imagePath = this.oss.host + "/" + key;
                  this.brandPic = imagePath;
                } else {
                  this.$message.error(`上传失败，请稍后重试`);
                  // e.target.value = "";
                }
              })
              .catch(error => {
                this.$message.error(`${error}`);
                //   this.isUplodSuccess = false;
                //   e.target.value = "";
              });
          } else {
            this.$message.error("请上传尺寸为90*90的图片");
          }
        };
      };
      if (file.file) {
        reader.readAsDataURL(file.file);
      }
    },
    //  清空表单数据
    clearData() {
      this.brandName = "";
      this.brandIntroduce = "";
      this.brandPic = "";
      this.fileList = [];
    },
    // 操作店铺
    async operateShopRequest(e) {
      e.preventDefault();
      this.form.validateFields(async err => {
        if (!err) {
          if (!this.brandPic) {
            this.$message.error("请上传图片!");
            return;
          }

          //  当前是新增还是修改
          let status;
          if (this.presentStatus === "add") {
            status = await addBrand({
              name: this.brandName,
              introduce: this.brandIntroduce,
              pic: this.brandPic
            });
          } else {
            status = await editBrand(this.recordRow.id, {
              name: this.brandName,
              introduce: this.brandIntroduce,
              pic: this.brandPic
            });
          }
          const { err, res } = status;
          if (!err) {
            if (res.success) {
              this.$message.success(
                this.presentStatus === "add"
                  ? "新增品牌成功！"
                  : "修改品牌成功！"
              );
              this.clearData();
              this.isOperateShop = false;
              this.getTopicList();
            }
          }
        }
      });
    },
    //  搜索
    search() {
      let shop_title =
        this.searchParams.selectType === "title" ? this.searchParams.name : "";
      let code =
        this.searchParams.selectType !== "title" ? this.searchParams.name : "";
      this.getTopicList(shop_title, code);
    },
    operateShop(type, record) {
      this.presentStatus = type;
      this.isOperateShop = true;
      this.clearData();

      if (type === "add") {
        this.modalTitle = "新建品牌";
      } else {
        this.modalTitle = "编辑品牌";
        this.form.setFieldsValue("brandName", record.name);
        this.form.setFieldsValue("brandIntroduce", record.introduce);
        this.form.setFieldsValue("brandPic", record.pic);
        this.brandName = record.name;
        this.brandIntroduce = record.introduce;
        this.brandPic = record.pic;
        this.recordRow = record; //  当前点击某一行的数据
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    addConfirm() {
      //  添加店铺
      this.isAddShop = true;
    },
    addConfirmLoad() {
      this.handleSubmit();
    },
    addCancel() {},
    // 搜索回调
    handleSearchCallback(searchParams) {
      this.searchParams = searchParams;
      this.getTopicList(searchParams.name);
    },
    // 获取专题列表
    async getTopicList(name) {
      if (!checkPer("basic_brand_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      this.listLoading = true;
      const { err, res } = await brandList({
        name,
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      });
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          this.list = res.data.results;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 点击查看报名详情
    handleShowTopicPreview(id) {
      this.$router.push({
        path: "/admin/operate/topicRegDetail",
        query: { id }
      });
    },
    // 分类
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getTopicList();
    }
  }
};
</script>

<style lang="less" scoped>
#brandManage {
  .ptb-10 {
    padding: 10px 0;
  }
}
.mr-30 {
  margin-right: 30px;
}
.form_btn {
  display: flex;
  justify-content: center;
}
.limitSize {
  margin-left: 20px;
  color: red;
  font-size: 12px;
}
</style>

<style scoped>
>>> .ant-modal-footer {
  display: none;
}
</style>
